.SrContentScript__Rocket,
.SrContentScript__RocketContainer {
  height: 350px;
  width: 360px;
}

@media (prefers-reduced-motion: no-preference) {
  .SrContentScript__Rocket {
    animation: SrContentScript__Rocket-spin infinite 4s linear;
  }
}

@keyframes SrContentScript__Rocket-spin {
  from {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(370deg);
  }

  to {
    transform: rotate(360deg);
  }
}
